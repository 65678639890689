const modal = document.querySelector("#modalContact") as HTMLElement
const btn = document.querySelector("#modalBtn") as HTMLElement
const closeBtn = document.querySelector(".close") as HTMLElement

btn?.addEventListener('click', () => {
  modal.style.display = "block"
});

closeBtn?.addEventListener('click', () => {
  modal.style.display = "none"
});

window?.addEventListener('click', (e) => {
  if (e.target == modal) {
    modal.style.display = "none"
  }
});

// reCaptcha v3 validation
grecaptcha.ready(function() {
  grecaptcha.execute('6Lfwu94iAAAAAPKTIk6LcoD-P-3fgQHnqbZB3CXE', {action: 'contactModal'})
    .then(function(token) {
      document.getElementById('captchaResponse').value = token
    });
});